// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XpeBbuoS8FovRi2J0t6U {
  position: fixed;
  align-self: center;
  max-width: 1220px;
  width: 100%;
  top: 0;
  left: 50%;
  right: 0;

  transform: translateX(-50%);
  padding: 36px;
  box-sizing: border-box;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

}

.qRrAeQdpLakey3gg2l7i {
  position: absolute;
  align-self: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;


  background-color: transparent;

  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/UIKit/PopupWithoutOverlay/popup.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,MAAM;EACN,SAAS;EACT,QAAQ;;EAER,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;;EAEtB,UAAU;;EAEV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;AAErB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;;;EAGT,6BAA6B;;EAE7B,eAAe;AACjB","sourcesContent":[".container {\n  position: fixed;\n  align-self: center;\n  max-width: 1220px;\n  width: 100%;\n  top: 0;\n  left: 50%;\n  right: 0;\n\n  transform: translateX(-50%);\n  padding: 36px;\n  box-sizing: border-box;\n\n  z-index: 1;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n}\n\n.overlay {\n  position: absolute;\n  align-self: center;\n  width: 100vw;\n  height: 100vh;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n\n\n  background-color: transparent;\n\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `XpeBbuoS8FovRi2J0t6U`,
	"overlay": `qRrAeQdpLakey3gg2l7i`
};
export default ___CSS_LOADER_EXPORT___;
