var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import "./App.css";
import RootRoute from "./Routes/RootRouter";
import { TIME_ZONES, getUserTimeZone, } from "./utils/utill_methods/getUserTimeZone";
import { useAppDispatch } from "./redux/store";
import { setUserTimeZone } from "./redux/authReducer";
function App() {
    var dispatch = useAppDispatch();
    useEffect(function () {
        var timezone = getUserTimeZone();
        // @ts-ignore
        var timezoneValue = TIME_ZONES[timezone];
        dispatch(setUserTimeZone({ timezone: { timezone: timezoneValue } }));
    }, []);
    return (_jsx("div", __assign({ className: "App", lang: "ru", translate: "no" }, { children: _jsx(RootRoute, {}) })));
}
export default App;
