import React from "react";
import ReactDOM from "react-dom";
export var Portal = function (_a) {
    var children = _a.children;
    var container = React.useState(function () { return document.createElement("div"); })[0];
    React.useEffect(function () {
        document.body.appendChild(container);
        return function () {
            document.body.removeChild(container);
        };
    }, []);
    return ReactDOM.createPortal(children, container);
};
